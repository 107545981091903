import request from '@/utils/request'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/**
 * Composition api for make a request (replacing this.$store.dispatch('http/[method]))
 * Will use in many component, be careful when want to change this function
 */
export default function useHttp () {
  const toast = useToast()
  const toastError = messages => toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: 'Error',
      icon: 'AlertCircleIcon',
      variant: 'danger',
      text: messages
    }
  })

  const $post = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'POST',
        ...payload
      })
        .then(res => resolve(res))
        .catch((error) => {
          toastError(error.message || error)
          reject(error.message || error)
        })
    })
  }

  const $get = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'GET',
        ...payload
      })
        .then(res => resolve(res))
        .catch((error) => {
          toastError(error.message || error)
          reject(error.message || error)
        })
    })
  }

  const $put = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'PUT',
        ...payload
      })
        .then(res => resolve(res))
        .catch((error) => {
          toastError(error.message || error)
          reject(error.message || error)
        })
    })
  }

  const $patch = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'PATCH',
        ...payload
      })
        .then(res => resolve(res))
        .catch((error) => {
          toastError(error.message || error)
          reject(error.message || error)
        })
    })
  }

  const $delete = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'DELETE',
        ...payload
      })
        .then(res => resolve(res))
        .catch((error) => {
          toastError(error.message || error)
          reject(error.message || error)
        })
    })
  }

  return {
    toast,
    $post,
    $get,
    $put,
    $patch,
    $delete
  }
}
